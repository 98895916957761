import React from 'react'

import Box from './Box'
import Text from './Text'

const InputError = ({ error, note, ...props }) => {
  return error ? (
    <Box.Absolute left={0} bottom={note ? '-3em' : '-1.5em'}>
      <Text color="danger" fontSize="0.875em" {...props}>{error}</Text>
    </Box.Absolute>
  ) : null
}

export default InputError
